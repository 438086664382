<template>
  <el-drawer
    title="異動內容"
    :visible="syncShow"
    direction="rtl"
    :destroy-on-close="true"
    class="member-invite-record"
    @close="$emit('close')"
  >
    <div v-loading="loading" class="wrapper">
      <el-form label-position="left" label-width="150px">
        <el-form-item label="異動類型">{{ displayData.type }}</el-form-item>
        <el-form-item label="展延後有效日期">{{ displayData.newExpAt }}</el-form-item>
        <el-form-item label="預計檢查人數">{{ displayData.planMemberCount }}</el-form-item>
        <el-form-item label="實際異動人數">{{ displayData.realMemberCount }}</el-form-item>
        <el-form-item label="異動對象">
          <div class="flex items-center" style="gap: 10px">
            <p>{{ displayData.conditionType }}</p>
            <el-button type="text" size="small" :loading="exporting" class="underline" @click="downloadChangeMemberList">
              <span class="text-sub">下載異動名單</span>
            </el-button>
          </div>

          <div v-if="!isEmpty(displayData.conditionsDetail)">
            <div v-for="(item, idx) in displayData.conditionsDetail" :key="idx">
              <div v-if="!isEmpty(item.includes)" class="flex items-start">
                <p class="flex-shrink-0">包含：</p>
                <p>{{ item.includes }}</p>
              </div>
              <div v-if="!isEmpty(item.excludes)" class="flex items-start">
                <p class="flex-shrink-0">排除：</p>
                <p>{{ item.excludes }}</p>
              </div>
              <p v-if="idx !== displayData.conditionsDetail.length - 1">Or</p>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="異動時間">{{ displayData.exeAt }}</el-form-item>
        <el-form-item label="操作人員">{{ displayData.operator }}</el-form-item>
        <el-form-item label="狀態">
          <Tag :type="get(displayData.status, 'tagType')">{{ get(displayData.status, 'label') }}</Tag>
        </el-form-item>
        <el-form-item label="備註">{{ displayData.note }}</el-form-item>
      </el-form>
    </div>
  </el-drawer>
</template>

<script>
import { useVModel } from '@vueuse/core'
import { computed, defineComponent, reactive, ref, watch } from 'vue'
import { get, map, join, isEmpty, find } from 'lodash'
import { FindMemberBatchTask, GetMemberBatchListTask, GetMembersBatchPointYearlyWallet, GetMembersBatchCashbackYearlyWallet } from '@/api/memberBatchTask'
import { useFetch } from '@/use/fetch'
import { useShop } from '@/use/shop'
import { formatDate } from '@/utils/date'
import { rewardPostponeTaskStatusConfig, rewardPostponeConditionTypesConfig, rewardPostponeTaskTypesConfig } from '@/config/rewardPostpone'

import { ExportExcel } from '@/utils/excel'
import dayjs from '@/lib/dayjs'

export default defineComponent({
  name: 'RewardPostponeDetail',
  components: {},
  props: {
    data: { type: Object, default: () => ({}) },
    memberTags: { type: Array, default: () => [] },
    show: Boolean,
  },
  setup (props, { emit }) {
    const syncShow = useVModel(props, 'show', emit)
    const loading = ref(true)
    const exporting = ref(false)
    const { simpleFetch, fetchAll } = useFetch()
    const { shopId } = useShop()
    const detailData = ref({})

    const displayData = computed(() => {
      const data = props.data || {}
      const type = get(detailData.value, 'type')
      const status = get(detailData.value, 'status')
      const conditionType = get(detailData.value, 'condition.type')
      const newExpAt = get(detailData.value, `settings.${type}.newExpirationDate`)

      const newYear = newExpAt ? dayjs(newExpAt).format('YYYY') : ''
      const preYear = get(detailData.value, `settings.${type}.year`)

      return {
        type: data.type,
        newExpAt: newExpAt ? formatDate(newExpAt) : '-',
        planMemberCount: data.planPeopleCount,
        realMemberCount: data.realPeopleCount,
        conditionType: get(rewardPostponeConditionTypesConfig, `${conditionType}.label`),
        conditionsDetail: formatTagCondition(get(detailData.value, 'condition.tagSettings')),
        exeAt: data.exeAt,
        operator: get(detailData.value, 'Creator.name') || '-',
        status: get(rewardPostponeTaskStatusConfig, status),
        note: data.note || `${preYear}年度獎勵展延至${newYear}年度獎勵`,
      }
    })

    const formatTagCondition = (conditions) => {
      return map(conditions, (condition) => {
        const excludes = map(condition.excludeTagIds, id => {
          const tag = find(props.memberTags, { id })
          if (tag) return tag.name
        })
        const includes = map(condition.includeTagIds, id => {
          const tag = find(props.memberTags, { id })
          if (tag) return tag.name
        })
        return {
          excludes: isEmpty(excludes) ? null : join(excludes, '、'),
          includes: isEmpty(includes) ? null : join(includes, '、'),
        }
      })
    }

    const downloadChangeMemberList = async () => {
      let apiMethod
      if (get(detailData.value, 'type') === rewardPostponeTaskTypesConfig.pointYearlyExtension.value) apiMethod = GetMembersBatchPointYearlyWallet
      else if (get(detailData.value, 'type') === rewardPostponeTaskTypesConfig.cashbackYearlyExtension.value) apiMethod = GetMembersBatchCashbackYearlyWallet

      let memberList = []
      let membersYearlyWallet = []

      exporting.value = true
      await fetchAll(GetMemberBatchListTask, { shopId: shopId.value, id: get(detailData.value, 'RealExecutionListId') }, (res) => {
        memberList = res
      })

      if (isEmpty(memberList)) {
        window.$message.warning('無資料可匯出!')
        exporting.value = false
        return
      }
      await fetchAll(apiMethod, {
        shopId: shopId.value,
        year: dayjs().year() - 1,
        memberIds: map(memberList, 'id'),
      }, (res) => {
        membersYearlyWallet = res
      })

      const exportData = map(memberList, member => {
        const wallet = find(membersYearlyWallet, { memberId: member.id })
        // pointWalletYearExpirations 因為同樣用點數的微服務，所以命名相同
        const yearlyWallet = find(get(wallet, 'pointWalletYearExpirations'), { year: dayjs().year() - 1 })
        return {
          會員姓名: get(member, 'UserInfo.name') || '-',
          電話: get(member, 'UserInfo.phone') || '-',
          前年度有效獎勵: yearlyWallet ? get(yearlyWallet, 'balance') : '-',
          前年度獎勵到期日: yearlyWallet ? formatDate(get(yearlyWallet, 'expirationDate')) : '-',
          // 展延後到期日: yearlyWallet ? formatDate(get(yearlyWallet, 'expirationDate')) : '-',
          是否為無效會員: get(member, 'isInvalid') ? '是' : '否',
        }
      })
      ExportExcel(exportData, '異動名單', '異動名單')
      exporting.value = false
    }

    watch(syncShow, async (show) => {
      if (!show) return
      if (!get(props.data, 'id')) return
      loading.value = true
      await simpleFetch(FindMemberBatchTask, { shopId: shopId.value, id: get(props.data, 'id') }, (res) => {
        detailData.value = res
      })
      loading.value = false
    })

    return { syncShow, displayData, loading, detailData, get, isEmpty, downloadChangeMemberList, exporting }
  },
})
</script>

<style lang="postcss" scoped>
.wrapper {
  @apply px-[20px];
}

::v-deep .el-form-item{
  @apply mb-0;
}
</style>
