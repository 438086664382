<template>
  <div class="reward-postpone-table">
    <el-table :data="displayData">
      <EmptyBlock slot="empty" />
      <el-table-column label="異動類型" prop="type" align="center" />
      <el-table-column label="展延有效日期" prop="newExpAt" align="center" />
      <el-table-column label="預計檢查人數" prop="planPeopleCount" align="center" />
      <el-table-column label="實際異動人數" prop="realPeopleCount" align="center" />
      <el-table-column label="異動時間" prop="exeAt" align="center" />
      <el-table-column label="狀態" prop="status" align="center" />
      <el-table-column label="備註" prop="note" align="center" />

      <el-table-column label="操作" fixed="right" width="110" align="center">
        <template slot-scope="scope">
          <TableEditBtnGroup
            editBtn="檢視"
            hideDelete
            @edit="onRowEdit(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>

    <RewardPostponeDetail
      :show.sync="show.detail"
      :data="selectRow"
      :memberTags="memberTags"
      @close="show.detail = false"
    />
  </div>
</template>

<script>
import { defineComponent, computed, ref, reactive, onMounted } from 'vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { get, map } from 'lodash'
import RewardPostponeDetail from './RewardPostponeDetail.vue'
import { rewardPostponeTaskTypesConfig, rewardPostponeTaskStatusConfig } from '@/config/rewardPostpone'
import { formatDate } from '@/utils/date'
import { useFetch } from '@/use/fetch'
import { GetTags } from '@/api/memberTags'
import { useShop } from '@/use/shop'
import dayjs from '@/lib/dayjs'

export default defineComponent({
  name: 'RewardPostponeTable',
  components: { EmptyBlock, RewardPostponeDetail },
  props: {
    tableData: { type: Array, defalt: () => [] },
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const { fetchAll } = useFetch()
    const { shopId } = useShop()
    const selectRow = ref(null)
    const show = reactive({
      detail: false,
    })
    const memberTags = ref([])

    const displayData = computed(() => {
      const data = props.tableData
      // handle data here
      return map(data, (item) => {
        const newExpAt = get(item, `settings.${item.type}.newExpirationDate`)

        const newYear = newExpAt ? dayjs(newExpAt).format('YYYY') : ''
        const preYear = get(item, `settings.${item.type}.year`)

        return {
          id: item.id,
          type: get(rewardPostponeTaskTypesConfig, `${item.type}.label`),
          newExpAt: newExpAt ? formatDate(newExpAt) : '-',
          status: get(rewardPostponeTaskStatusConfig, `${item.status}.label`),
          planPeopleCount: get(item, 'PlanCheckList.memberCount'),
          realPeopleCount: get(item, 'RealExecutionList.memberCount'),
          exeAt: get(item, 'realExecutionAt') ? formatDate(get(item, 'realExecutionAt')) : '-',
          note: item.note || `${preYear}年度獎勵展延至${newYear}年度獎勵`,
        }
      })
    })

    const onRowEdit = (row) => {
      selectRow.value = row
      show.detail = true
    }

    onMounted(async () => {
      fetchAll(GetTags, { shopId: shopId.value }, (tags) => {
        memberTags.value = tags
      })
    })

    return {
      show,
      displayData,
      onRowEdit,
      selectRow,
      memberTags,
    }
  },
})
</script>
